import { useQuery, useMutation, useQueryClient } from 'react-query';
import { encryptHex, encrypt, decrypt } from './Encryption';

const SERVER = 'https://baseinfo.de-falco.fr/api';
//const SERVER = 'http://localhost:5000';

const fetchJson = (url, options = {}) => {

    return fetch(url, {
        ...options,
        headers: {
            ...options.headers,
            'Content-Type': 'application/json',
            'Authorization': 'token ' + encryptHex('1234567890'),
        },
        body : options.data
    })
    .then((res) => {
        if (!res.ok) {
            throw new Error(res.statusText);
        }
        return res.json();
    })
    .then((data) => {
        // Function to parse the content field
        const parseContentField = (item) => {
            if (item && item.content && typeof item.content === 'string') {
                try {
                    item.content = JSON.parse(decrypt(item.content));
                } catch (error) {
                    console.error('Failed to parse content:', error);
                }
            }
            if (item && item.details && typeof item.details === 'string') {
                try {
                    item.details = JSON.parse(decrypt(item.details));
                } catch (error) {
                    console.error('Failed to parse content:', error);
                }
            }

        };

        // Check if data is an array (list of items)
        if (Array.isArray(data)) {
            data.forEach(parseContentField);
            return data.reduce((acc, item) => {
                acc[item.uuid] = item.content;
                return acc;
            }, {});
        } else {
            // Handle single object
            parseContentField(data);
            return data;
        }
    });
};

export const useItem = (uuid) =>
    useQuery(['data', uuid], () => 
          uuid ? fetchJson(`${SERVER}/data/${uuid}`)
               : null);

export const useItemsByType = (type) =>
    useQuery(['data', 'type', type], () => fetchJson(`${SERVER}/data/type/${type}`));

export const useDeleteItem = (type) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ uuid, content }) => fetchJson(`${SERVER}/data/${uuid}`, {
            method: 'DELETE',
        }),
        {
            onSuccess: (data, variables) => {
                queryClient.setQueryData(['data', 'type', type], (oldData) => {
                    return oldData.filter((item) => item.uuid !== variables.uuid);
                });
                // Update the list of items in the cache
                // queryClient.invalidateQueries('data', 'type', type);
            },
        }
    );
}

export const useUpdateItem = (type) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ uuid, content }) => fetchJson(`${SERVER}/data/${uuid}`, {
            method: 'PUT',
            data: encrypt(JSON.stringify(content)),
        }),
        {
            onSuccess: (data, variables) => {
                // Update the individual item in the cache
                queryClient.setQueryData(['data', 'type', type], (oldData) => {
                    return {
                        ...oldData, 
                        [variables.uuid]: variables.content
                    };
                });
                // Update the list of items in the cache
                // queryClient.invalidateQueries('data', 'type', type);
            },
        }
    );
}

export const useUpdateItems = (type) => {
    const queryClient = useQueryClient();
    return useMutation(
        ( items ) => fetchJson(`${SERVER}/data/mass`, {
                method: 'POST',
                data: JSON.stringify(items.map((item) => ({
                    uuid: item.uuid,
                    content: encrypt(JSON.stringify(item.content)),
                }))),
            }),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries('data', type);
                },
            });
};

export const useCreateItem = (type) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ content }) => fetchJson(`${SERVER}/data/type/${type}`, {
                method: 'POST',
                data: encrypt(JSON.stringify(content)),
            }),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries('data', 'type', type);
                },
            });
}
