import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'antd';

const EditableDiv = ({ value, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onUpdate(inputValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  return isEditing ? (
    <Input.TextArea
      ref={inputRef}
      rows={4}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    />
  ) : (
    <div onDoubleClick={handleDoubleClick}>
      {inputValue || <i>Double-cliquez pour ajouter un commentaire</i> }
    </div>
  );
};

export default EditableDiv;
