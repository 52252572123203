import React, { useEffect, useState } from 'react';
import { useItem, useCreateItem, useUpdateItem, useDeleteItem, useItemsByType } from '../api';
import { Tabs, Space, Input, InputNumber, Button, Modal, Form, Switch, Select, Table, Row, Col, Card, Spin, Alert } from 'antd';
import { EditOutlined } from '@ant-design/icons';

function PosteModal({ posteUUID, onChange, isModalOpen, setIsModalOpen }) {
    const createItem = useCreateItem('poste');
    const deleteItem = useDeleteItem('poste');
    const [form] = Form.useForm();

    const { data: poste, isLoading, error } = useItem(posteUUID);
    useEffect(() => {
        form.resetFields();
        if (poste) {
            form.setFieldsValue({
                'suivi': poste.content.suivi,
                'ACA': poste.content.ACA,
                'ETB': poste.content.ETB,
                'COM': poste.content.COM,
                'GEO': poste.content.GEO,
                'DPT': poste.content.DPT,
                'etablissement': poste.content.etablissement,
                'prive': poste.content.prive,
                'classe': poste.content.classe,
                'discipline': poste.content.discipline,
            });
        }
    }, [poste]);

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    const handleDelete = () => {
        if (window.confirm('Voulez-vous vraiment supprimer ce poste ?')) {
            deleteItem.mutate({ uuid: posteUUID });
            setIsModalOpen(false);
        }
    };

    const handleFinish = (values) => {
        if (poste) {
            if(onChange) {
                console.log('Updating poste:', posteUUID, values);
                onChange(posteUUID, values);
            }
        } else {
            const defaultPoste = {
                suivi: '',
                ACA: '',
                ETB: '',
                COM: '',
                GEO: '',
                DPT: '',
                etablissement: '',
                prive: false,
                classe: '',
                discipline: '',
                mouvement: {
                    a_pourvoir: true,
                    proposition: null,
                    atd: false,
                    commentaire: '',
                },
                occupant: null,
            }

            createItem.mutate(
                { content: 
                    { ...defaultPoste, ...values  }
                },
                { onSuccess: () => console.log('Successfully created poste') }
            );
        }
        setIsModalOpen(false);
    };

    return (
        <>
            <Modal title={poste ? 'Modifie poste' : 'Ajoute poste'} 
                width={800}
                open={isModalOpen} onCancel={handleCancel} footer={null}>
                <Form form={form} onFinish={handleFinish}>
                    <Form.Item name="suivi" label="Suivi"><Input /></Form.Item>
                    <Form.Item name="ACA" label="ACA"><Input /></Form.Item>
                    <Form.Item name="ETB" label="ETB"><Input /></Form.Item>
                    <Form.Item name="COM" label="COM"><Input /></Form.Item>
                    <Form.Item name="GEO" label="GEO"><Input /></Form.Item>
                    <Form.Item name="DPT" label="DPT"><Input /></Form.Item>
                    <Form.Item name="etablissement" label="Établissement"><Input /></Form.Item>
                    <Form.Item name="discipline">
                        <Select placeholder="Discipline" style={{ width: 120 }}>
                        <Select.Option value="Info">Info</Select.Option>
                        <Select.Option value="Maths">Maths</Select.Option>
                        <Select.Option value="SII">SII</Select.Option>
                        <Select.Option value="EG">EG</Select.Option>
                        <Select.Option value="PC">PC</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="prive" label="Privé" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Form.Item name="classe" label="Classe"><Input /></Form.Item>
                    
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {poste ? 'Modifie poste' : 'Ajoute poste'}
                        </Button>
                        <Button onClick={handleDelete}>Supprime poste</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}


function PosteDisplayModal({ posteUUID, onChange }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    return (
        <>
        <Button type="primary" onClick={() => showModal()}
            icon={<EditOutlined/> } size='small' />
        { isModalOpen &&
            <PosteModal 
                posteUUID={ posteUUID } onChange={ onChange }
                isModalOpen={ isModalOpen } setIsModalOpen={ setIsModalOpen }
            /> }
        </>
    );
}


export default PosteDisplayModal;
