import React, { useMemo } from 'react';
import { useItemsByType, useUpdateItem } from '../api';
import { Tag, Switch, Select, Table, Row, Col, Card, Spin, Alert } from 'antd';
import PosteModal from './PosteModal';
const { Option } = Select;

function PostesList() {
    const updateItem = useUpdateItem('poste');

    const {
        data: enseignants,
        isLoading: isLoadingEnseignants,
        error: errorEnseignants,
    } = useItemsByType('enseignant');

    const {
        data: postes,
        isLoading: isLoadingPostes,
        error: errorPostes,
    } = useItemsByType('poste');

    const dataPostes = useMemo(() => {
        return Object.entries(postes || null).map(([uuid, poste]) => {
        const enseignant = enseignants[poste.occupant] || null;
        return {
            ...poste,
            'poste': uuid,
            'occupant': enseignant ? enseignant.civilite + ' ' + enseignant.nom + ' ' + enseignant.prenom : '',
        };
    });}, [postes, enseignants]);

    if (isLoadingPostes || isLoadingEnseignants) return <Spin size="large" />;
    if (errorPostes) return <Alert message="Error" description={errorPostes.message} 
    type="error" showIcon />;
    if (errorEnseignants) return <Alert message="Error" description={errorEnseignants.message} 
    type="error" showIcon />;


    const handleDisciplineChange = (newDiscipline, record) => {
        updateItem.mutate(
            { uuid: record.poste, 
              content: { ...postes[record.poste], discipline: newDiscipline } },
            {
                onSuccess: () => {
                    console.log('Successfully updated discipline');
                }
            }
        );
    };

    const handleChange = (record, values) => {
        updateItem.mutate(
            { uuid: record.poste, 
              content: { ...postes[record.poste], ...values } },
            {
                onSuccess: () => {
                    console.log('Successfully updated poste', record, values);
                }
            }
        );
    };

    const handleChangeMouvement = (record, values) => {
        const poste = postes[record.poste];
        updateItem.mutate(
            { uuid: record.poste, 
                content: { ...poste, mouvement: { ...poste.mouvement, ...values } } },
            {
                onSuccess: () => {
                    console.log('Successfully updated poste');
                }
            }
        );
    };

    const columns = [
        { title: 'Zoom', dataIndex: 'poste', key: 'poste',
            render: (_, record) => (
                <PosteModal
                    posteUUID={ record.poste }
                onChange={ (posteUUID, valeurs) => 
                    handleChange({ poste: posteUUID }, valeurs)
                } />
            )
        },
        { title: 'Classe', dataIndex: 'classe', key: 'classe',
            sorter: (a, b) => a.classe.localeCompare(b.classe),
            sortDirections: ['descend', 'ascend'],
            onFilter: (value, record) => record.classe === value,
            filters: [ 
                { text: 'MP2I', value: 'MP2I' },
                { text: 'MPI', value: 'MPI' },
                { text: 'ITC', value: 'ITC' },
            ]
        },
        { title: 'Etablissement', dataIndex: 'etablissement', key: 'etablissement',
            sorter: (a, b) => a.etablissement.localeCompare(b.etablissement),
            sortDirections: ['descend', 'ascend'], },
        { title: 'Ville', dataIndex: 'COM', key: 'COM' },
        { title: 'Académie', dataIndex: 'ACA', key: 'ACA',
            defaultSortOrder: 'ascend',
            sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.ACA.localeCompare(b.ACA),},
        { title: 'Suivi', dataIndex: 'suivi', key: 'suivi' },
        { title: 'Privé', dataIndex: 'prive', key: 'prive',
            render: (prive, record) =>
            <Switch checked={prive}  /> },
        { title: 'À pourvoir', dataIndex: 'mouvement.a_pourvoir', key: 'a_pourvoir',
            render: (a_pourvoir, record) => 
            <Switch checked={a_pourvoir} onChange={(newA_pourvoir) => handleChangeMouvement(record, { a_pourvoir: newA_pourvoir })} /> },
        {
            title: 'Discipline', 
            dataIndex: 'discipline', 
            key: 'discipline',
            render: (discipline, record) => (
                <Select 
                key={ record.poste.uuid }
                value={discipline} 
                onChange={(newDiscipline) => handleDisciplineChange(newDiscipline, record)}
                style={{ width: 100 }}> 
                <Option value="Maths">Maths</Option>
                <Option value="Info">Info</Option>
                <Option value="SII">SII</Option>
                <Option value="EG">EG</Option>
                <Option value="PC">PC</Option>
                </Select>
            )
        },
        { title: 'Occupant', dataIndex: 'occupant', key: 'occupant' },
        { title: 'ATD', dataIndex: 'atd', key: 'atd',
            render: (atd, record) => 
                atd ? <Tag color='green' key={record.enseignant}>ATD</Tag> : ''
        },
        { title: 'Mvt', dataIndex: ['mouvement','a_pourvoir'], key: 'a_pourvoir',
            render: (a_pourvoir, record) =>
                a_pourvoir 
            && ( record.mouvement.proposition
                ? <>{ enseignants[record.mouvement.proposition].nom } <Tag color={ record.mouvement.atd ? 'green' : 'red' }  key={record.enseignant}>{ record.mouvement.atd ? 'ATD' : 'ATP' }</Tag></>
                : <Tag color='red' key={record.enseignant}>Vacant</Tag>)
        },
    ];

    return (
        <>
        Ajouter un poste <PosteModal />
        <Table dataSource={ dataPostes } size='small' pagination={{ pageSize:300, showSizeChanger: false }} columns={ columns } />
        </>
    );

}

export default PostesList;
