import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import logo from './logo.svg';
import './App.css';
import { Input, Button, Form, Modal, Tabs } from 'antd';
import Login from './components/Login';
import EnseignantsList from './components/EnseignantsList';
import PostesList from './components/PostesList';
import Mouvement from './components/Mouvement';
import MouvementSynthese from './components/MouvementSynthese';
import KeyPromptModal from './components/KeyPromptModal';
import { validateKey } from './Encryption';

const { TabPane } = Tabs;

const queryClient = new QueryClient();

function App() {
  const [isKeyValid, setIsKeyValid] = useState(() => {
    const storedKey = localStorage.getItem('encryptionKey');
    return storedKey && validateKey(storedKey);
  });

  return (
      <>
        {!isKeyValid && <KeyPromptModal onKeyValidated={ () => setIsKeyValid(true) } />}
        {isKeyValid &&
          <QueryClientProvider client={queryClient}>
            <div className="App">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Enseignants" key="1">
                  <EnseignantsList />
                </TabPane>
                <TabPane tab="Postes" key="2">
                  <PostesList />
                </TabPane>
                <TabPane tab="Mouvement" key="3">
                  <Mouvement />
                </TabPane>
                <TabPane tab="Synthèse Mouvement" key="4">
                  <MouvementSynthese />
                </TabPane>
              </Tabs> 
            </div>
          </QueryClientProvider>}
      </>
  );
}

export default App;
