import React, { useState } from 'react';
import { useUpdateItem, useItemsByType } from '../api';
import { Tag, Form, Switch, Select, Table, Row, Col, Card, Spin, Alert } from 'antd';
import EnseignantModal from './EnseignantModal';

const { Option } = Select;

function EnseignantsList() {
    const updateItem = useUpdateItem('enseignant');

    const {
        data: enseignants,
        isLoading: isLoadingEnseignants,
        error: errorEnseignants,
    } = useItemsByType('enseignant');

    const {
        data: postes,
        isLoading: isLoadingPostes,
        error: errorPostes,
    } = useItemsByType('poste');

    if (isLoadingPostes || isLoadingEnseignants) return <Spin size="large" />;
    if (errorPostes) return <Alert message="Error" description={errorPostes.message} 
    type="error" showIcon />;
    if (errorEnseignants) return <Alert message="Error" description={errorEnseignants.message} 
    type="error" showIcon />;

    const dataEnseignants = Object.entries(enseignants).map(([uuid, enseignant]) => {
        const poste = postes[enseignant.occupe] || null;
        return {
            ...enseignant,
            'enseignant' : uuid,
            'occupe': poste,
        };
    });

    const handleChange = (enseignantUUID, valeurs) => {
        updateItem.mutate(
            {   uuid: enseignantUUID, 
                content: { ...enseignants[enseignantUUID], ...valeurs } },
            {
                onSuccess: () => {
                    console.log('Successfully updated enseignant');
                }
            }
        );
    };

    const columns = [
        { title: 'Édition', dataIndex: 'enseignant', key: 'enseignant',
            render: (_, record) => (
                <EnseignantModal 
                        enseignantUUID={record.enseignant}
                    onChange={ handleChange } />
            )
        },
        { title: 'Civilité', dataIndex: 'civilite', key: 'civilite' },
        { title: 'Nom', dataIndex: 'nom', key: 'nom',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.nom.localeCompare(b.nom),
            sortDirections: ['descend', 'ascend'],
        },
        { title: 'Prénom', dataIndex: 'prenom', key: 'prenom' },
        {
            title: 'Discipline', 
            dataIndex: 'discipline', 
            key: 'discipline',
            sorter: (a, b) => a.discipline.localeCompare(b.discipline),
        },
        { title: 'Académie', dataIndex: ['occupe','ACA'], key: 'academie',
          sorter: (a, b) => {
            if (a.occupe && b.occupe) {
                return a.occupe.ACA.localeCompare(b.occupe.ACA);
            }
            if (a.occupe) {
                return -1;
            }
            if (b.occupe) {
                return 1;
            }
            return 0;
          }
        },
        { title: "Classe", dataIndex: ['occupe','classe'], key: 'classe' },
        { title: 'Établissement', dataIndex: ['occupe','etablissement'], key: 'etablissement' },
        { title: 'Rang', dataIndex: 'rang', key: 'rang' },
        { title: 'Concours', dataIndex: 'concours', key: 'concours' },
        { title: 'Disc. Recr.', dataIndex: 'discipline_recrutement', key: 'discipline_recrutement' },
        { title: 'Annee', dataIndex: 'annee', key: 'annee' },
        { title: 'Echelon', dataIndex: ['carriere','echelon'], key: 'echelon',
            render: (echelon, record) =>
                echelon == 1 ? (<Tag color='blue' key={record.enseignant}>Stagiaire</Tag>) : echelon
        },
        { title: 'ATD', dataIndex: ['occupe','atd'], key: 'atd',
            render: (atd, record) => 
                atd ? <Tag color='green' key={record.enseignant}>ATD</Tag> : ''
        },
        { title: 'Suivi', dataIndex: ['occupe','suivi'], key: 'suivi',
            sorter: (a, b) => {
                if (a.occupe && b.occupe) {
                    return a.occupe.suivi.localeCompare(b.occupe.suivi);
                }
                if (a.occupe) {
                    return -1;
                }
                if (b.occupe) {
                    return 1;
                }
                return 0;
            }
        },
        { title: 'Email', dataIndex: 'email', key: 'email' },
    ];

    return (
        <>
        Ajouter un enseignant <EnseignantModal />
        <Table dataSource={ dataEnseignants } 
        rowClassName={(record, index) =>  (record.voeux && record.voeux.length > 0) ? 'row-mouvement' : ''}
        size='small' pagination={{ pageSize:300, showSizeChanger: false }} columns={ columns } />
        </>
    );
}

export default EnseignantsList;
