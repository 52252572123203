import React from 'react';
import { Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { useItem } from '../api';

const OpenPDFButton = ({ base64PDF }) => {
  const handleOpenPDF = () => {
    // Convert base64 to raw binary data held in a string
    const raw = window.atob(base64PDF);

    // Convert that text into a byte array
    const rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([array], { type: 'application/pdf' });

    // Create an object URL for the blob object
    const url = URL.createObjectURL(blob);

    // Open the PDF in a new window
    window.open(url);
  };

  return (
    <Button type="button" icon={<FilePdfOutlined />} onClick={handleOpenPDF}></Button>
  );
};

export const OpenPDFDossierEnseignant = ({ enseignantUUID }) => {
    const { data: enseignant, isLoading, error } = useItem(enseignantUUID);
    
    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Erreur !</p>;
    if (!enseignant) return <p>Enseignant non trouvé</p>;

    return <OpenPDFButton base64PDF={enseignant.details.dossier_pdf} />;
};

export default OpenPDFButton;
