// KeyPromptModal.js
import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { validateKey } from '../Encryption';
import CryptoJS from 'crypto-js';

function KeyPromptModal({ onKeyValidated }) {
  const [localKey, setLocalKey] = useState('');

  const handleSubmit = () => {
      if (validateKey(localKey)) {
          localStorage.setItem('encryptionKey', localKey);
          onKeyValidated();
      } else {
          alert('Clé invalide');
      }
  };

  return (
    <Modal title="Entrez la clé de chiffrement" visible={true} onOk={handleSubmit} closable={false}>
      <Input.Password 
        value={localKey}
        onChange={(e) => setLocalKey(e.target.value)} 
        onPressEnter={handleSubmit}
      />
    </Modal>
  );
}

export default KeyPromptModal;
