import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useItemsByType, useUpdateItem, useUpdateItems, useCreateItem } from '../api';
import { Table, Tabs, Modal, Popover, Input, Tag, List, Button, Switch, Select, Row, Col, Card, Spin, Alert } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import EnseignantModal from './EnseignantModal';
import EditableDiv from './EditableDiv';
import { OpenPDFDossierEnseignant } from './OpenPDFButton';

const { Option } = Select;
const { TabPane } = Tabs;

const disciplineColor = (discipline) => {
        if (discipline === 'Info')
            return 'blue';
        if (discipline === 'Maths')
            return 'green';
        if (discipline === 'SII')
            return 'orange';
        if (discipline === 'EG')
            return 'purple';
        if (discipline === 'PC')
            return 'red';
        return 'black';
    };


const compat = (poste, enseignant) => {
    var rang = undefined;
    if (!enseignant.voeux) return false;
    enseignant.voeux.forEach((voeu) => {
        var valid = false;
        if(voeu.type === 'ACA' && voeu.valeur !== '' && voeu.valeur === poste.ACA)
            valid = true;
        if(voeu.type === 'ACA' && voeu.valeur === 'TOUTES')
            valid = true;
        if(voeu.type === 'DPT' && voeu.valeur !== '' && voeu.valeur === poste.DPT)
            valid = true;
        if(voeu.type === 'GEO' && voeu.valeur !== '' && voeu.valeur === poste.GEO)
            valid = true;
        if(voeu.type === 'ETB' && poste.ETB !== '' && voeu.valeur.indexOf(poste.ETB) !== -1)
            valid = true;
        if(voeu.type === 'COM' && voeu.valeur !== '' && voeu.valeur === poste.COM)
            valid = true;
        if (valid) {
            if (rang) {
                rang = Math.min(rang, voeu.rang);
            } else {
                rang = voeu.rang;
            }
        }
    });
    return rang;
};

const computeCompatibilites = (postes, enseignants) => {
    const compatibilites = {
    };

    Object.entries(postes).forEach( ([posteUUID, poste]) => {
        compatibilites[posteUUID] = 
            Object.entries(enseignants).map(([enseignantUUID, enseignant]) => {
                const rang = compat(poste, enseignant);
                return rang ? [ enseignantUUID, enseignant, rang ] : undefined;
            }).filter( (item) => item ).sort( (a, b) => a[2] - b[2] );
    });

    return compatibilites;
}

function AjouteScenarioModal({ postes }) {
    const [nom, setNom] = useState('');
    const [isOpened, setIsOpened] = useState(false);

    const addScenario = useCreateItem('scenario');

    const ajouteScenario = () => {
        addScenario.mutate(
            { 
                content: { nom: nom, 
                propositions: Object.entries(postes).reduce((acc, [posteUUID, poste]) => {
                    acc[posteUUID] = poste.mouvement.proposition || poste.occupant;
                    return acc;
                }, {})
                } },
            {
                onSuccess: () => {
                    console.log('Successfully added scenario');
                    setIsOpened(false);
                }
            });
    }

    return (
        <>
        <Button type="primary" onClick={ () => setIsOpened(true) }>Ajouter un scénario</Button>
        <Modal title="Ajouter un scénario" visible={ isOpened } 
            onOk={ ajouteScenario } onCancel={ () => setIsOpened(false) }>
        <Input value={ nom } onChange={ (event) => setNom(event.target.value) } />
        </Modal>
        </>
    );
}

function MouvementPourvoirModal({ posteUUID, postes, enseignants, onCancel }) {
    const updateEnseignant= useUpdateItem('enseignant');
    const updatePoste= useUpdateItem('poste');

    const propositions = [];
    const poste = postes && posteUUID && postes[posteUUID];

    const compatibilites = useMemo(() => {
        return (enseignants && poste) 
            ? Object.entries(enseignants).map(([enseignantUUID, enseignant]) => {
                const rang = compat(poste, enseignant);
                return rang ? [ enseignantUUID, enseignant, rang ] : undefined;
            })
        .filter( (item) => item )
        .map( ([enseignantUUID, enseignant, rang]) => {
            return {
                enseignantUUID: enseignantUUID,
                enseignant: enseignant,
                proposition: Object.entries(postes).find(([posteUUID, poste]) => poste.mouvement.proposition === enseignantUUID),
                rang: rang
            };
        } )
        .sort( (a, b) => a.rang - b.rang )
            : [];
    }, [posteUUID, enseignants, postes]);

    const handleChangeEnseignant = (enseignantUUID, valeurs) => {
        updateEnseignant.mutate(
            {   uuid: enseignantUUID, 
                content: { ...enseignants[enseignantUUID], ...valeurs } },
            {
                onSuccess: () => {
                    console.log('Successfully updated enseignant');
                }
            }
        );
    };

    const handleChangePoste = (posteUUID, valeurs) => {
        updatePoste.mutate(
            {   uuid: posteUUID, 
                content: { ...postes[posteUUID], ...valeurs } },
            {
                onSuccess: () => {
                    console.log('Successfully updated poste');
                }
            }
        );
    };


    const handleChangePosteMouvement = (posteUUID, valeurs) => {
        updatePoste.mutate(
            {   uuid: posteUUID, 
                content: { ...postes[posteUUID], 
                    mouvement: {
                        ...postes[posteUUID].mouvement,
                        ...valeurs 
                    } } },
            {
                onSuccess: () => {
                    console.log('Successfully updated poste mouvement');
                }
            }
        );
    };

    const handleChangePosteProposition = (posteUUID, enseignantUUID) => {
        let precedent = null;
        Object.entries(postes).forEach(([posteUUID2, poste]) => {
            if (poste.mouvement.proposition === enseignantUUID) {
                precedent = posteUUID2;
            }
        });
        if (precedent) 
            handleChangePosteMouvement(precedent, { proposition: null });
        handleChangePosteMouvement(posteUUID, { proposition: enseignantUUID });
    };

    return (
        <Modal 
            onCancel={ onCancel }
            footer={null}
            width={800}
            title={
            poste && (poste.etablissement 
                + ' ' + poste.classe + ' ' + poste.discipline)}
            visible={ poste !== null }>

    { poste && <EditableDiv
                    value={ poste.mouvement.commentaire }
                    onUpdate={ (newValue) => 
                handleChangePosteMouvement(posteUUID, { commentaire: newValue }) }
                /> }
    { poste && 
            <>
            Affecter en ATD <Switch checked={ poste.mouvement.atd } 
                onChange={ (checked) => 
                handleChangePosteMouvement(posteUUID, { atd: checked }) } />
        </> }
    <List
  size="small"
  bordered
  dataSource={compatibilites}
  renderItem={item => (
      <List.Item
        actions={[
          item.proposition
        ? (item.proposition[0] === posteUUID
          ? <Tag color="blue">
            { item.enseignant.occupe === posteUUID ? 'Maintien' : 'Proposition' }
            </Tag>
          : <Popover
                content={
                    (postes[item.enseignant.occupe]?
                    (postes[item.enseignant.occupe].etablissement
                    + ' '
                    + postes[item.enseignant.occupe].classe):'')
                }
            >
                <Tag color="red">Déjà affecté</Tag>
            </Popover>)
            : '',
          poste.mouvement.proposition === item.enseignantUUID
            ? <Button type="primary" onClick={ () => 
                handleChangePosteMouvement(posteUUID, { proposition: null }) }>Retirer</Button>
            : <Button onClick={ () => 
                handleChangePosteProposition(posteUUID, item.enseignantUUID) }>Affecter</Button>
           ,
          <EnseignantModal
            enseignantUUID={item.enseignantUUID}
            enseignant={item.enseignant}
            onChange={handleChangeEnseignant}
          />,
          <OpenPDFDossierEnseignant enseignantUUID={item.enseignantUUID} />
        ]}
      >
        <div style={{ flex: '1 1 auto' }}>
          <span style={{ fontWeight: 'bold' }}>
        <Tag>
        { item.enseignant.occupe ? ('Mutation ' + (postes[item.enseignant.occupe].atd ? 'ATD' : 'ATP')) : 'Extérieur' }
        </Tag>
          {item.enseignant.prenom + ' ' + item.enseignant.nom + ' '}
      <Tag color={disciplineColor(item.enseignant.discipline)}>{item.enseignant.discipline}</Tag>
      { item.enseignant.carriere && item.enseignant.carriere.echelon == 1 && <Tag>stagiaire</Tag>}
      </span>
      <br/>
      { item.enseignant.rang }e {item.enseignant.concours} {item.enseignant.discipline_recrutement} {item.enseignant.annee}
      { item.enseignant.naissance && (', ' + ( 2024 - item.enseignant.naissance ) + ' ans') }
      <Popover content={ 
          <List size="small" bordered={false}>
          { item.enseignant.voeux.sort((a, b) => a.rang - b.rang).map((voeu) =>
              <List.Item key={voeu.rang} style={ voeu.rang == item.rang ? { backgroundColor: '#ffcccc' } : {} }>
              { voeu.rang } { voeu.type } { voeu.valeur }
              </List.Item>
          ) }
          </List>
      }>
          <span style={{ float: 'right' }}>Vœu {item.rang}/{item.enseignant.voeux.length}</span>
      </Popover>
      { item.enseignant.tags 
        && <><br/> {item.enseignant.tags.map((tag) => <Tag key={tag}>{tag}</Tag>) } </>}
      <br/> { item.enseignant.commentaire }
        </div>
      </List.Item>
  )}
/>

        </Modal>
    );
}

function Mouvement() {
    const [ pourvoir, setPourvoir ] = useState(null);
    const {
        data: enseignants,
        isLoading: isLoadingEnseignants,
        error: errorEnseignants,
    } = useItemsByType('enseignant');

    const {
        data: postes,
        isLoading: isLoadingPostes,
        error: errorPostes,
    } = useItemsByType('poste');

    const categories = useMemo(() =>
        (Object.entries(postes || null).reduce((acc, [posteUUID, poste]) => {
            if (poste.mouvement.a_pourvoir
                || (poste.mouvement.proposition === null
                    && poste.occupant !== null))
            {
                if(poste.mouvement.proposition === null)
                    acc.vacant.push(posteUUID);
                else {
                    if (poste.discipline === enseignants[poste.mouvement.proposition].discipline)
                        acc.pourvu_atd_possible.push(posteUUID);
                    else
                        acc.pourvu_atd_impossible.push(posteUUID);
                }
            }
            return acc;
        }, {
            vacant: [], 
            pourvu_atd_possible: [], 
            pourvu_atd_impossible: []
        }))
    );

        return (
        <>
        <MouvementPourvoirModal 
            posteUUID={ pourvoir }
            postes={ postes }
            enseignants={ enseignants }
            onCancel={ () => setPourvoir(null) }
        />
        { [ ['Vacants', categories.vacant],
            ['Pourvus ATD possible', categories.pourvu_atd_possible],
            ['Pourvus ATD impossible', categories.pourvu_atd_impossible] ].map(([tab, selectedPostes]) =>
        <>
        <h2>{ tab }</h2>
        <List
            grid={{ gutter: 0, 
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 3
            }}
            bordered
            dataSource={selectedPostes
                    ? selectedPostes.map((posteUUID) => [posteUUID, postes[posteUUID]])
                    : []}
            renderItem={([posteUUID, poste]) => (
                <List.Item>
                <Card
                actions={[
                    <span onClick={ () => { setPourvoir(posteUUID); } }
                    ><EditOutlined key="pourvoir"/>
                    Pourvoir</span>
                ]}
                >
                <Card.Meta
                    avatar={
                <Tag
                color={ disciplineColor(poste.discipline) }>
                { poste.discipline }
                </Tag> }
                    title={ poste.ACA + ' ' + poste.etablissement + ' ' + poste.classe } />
                { poste.occupant
                ? (poste.mouvement.proposition !== poste.occupant ? 'Départ de ' + enseignants[poste.occupant].nom 
  : ( <div> Enseignant en { poste.atd ? 'ATD' : 'ATP' } maintenu</div> )
                )
                : 'Poste préc. vacant' }
                <br/>
                { poste.mouvement.proposition
                ? <>
        { poste.mouvement.atd ? <Tag color="blue">ATD</Tag>
            : <Tag color="red">ATP</Tag> }
    { enseignants[poste.mouvement.proposition].nom + ' ' 
     + enseignants[poste.mouvement.proposition].prenom + ' ' }
                    <span style={{ color: disciplineColor(enseignants[poste.mouvement.proposition].discipline) }}>
    { enseignants[poste.mouvement.proposition].discipline }
                    </span>
                    </>
                : 'Pas encore de proposition' }
                </Card>
                </List.Item>
            )}
        />
        </>
    ) }
        </>
    );
}

function Mouvement_() {
    const [pourvoir, setPourvoir] = useState(null);

    const { data: enseignants } = useItemsByType('enseignant');
    const { data: postes } = useItemsByType('poste');

    const dataSource = useMemo(() => {
        return Object.entries(postes || {}).map(([posteUUID, poste]) => {
            const estVacant = poste.mouvement.proposition === null && poste.occupant !== null;
            const pourvuAtdPossible = poste.mouvement.proposition !== null && poste.discipline === enseignants[poste.mouvement.proposition]?.discipline;
            const pourvuAtdImpossible = poste.mouvement.proposition !== null && poste.discipline !== enseignants[poste.mouvement.proposition]?.discipline;

            return {
                key: posteUUID,
                discipline: poste.discipline,
                ACA: poste.ACA,
                etablissement: poste.etablissement,
                classe: poste.classe,
                status: estVacant ? 'Vacant' :
                        pourvuAtdPossible ? 'Pourvu ATD possible' :
                        pourvuAtdImpossible ? 'Pourvu ATD impossible' : 'Inconnu',
                action: posteUUID,
                occupant: poste.occupant ? enseignants[poste.occupant]?.nom : 'N/A',
                proposition: poste.mouvement.proposition ? enseignants[poste.mouvement.proposition]?.nom : 'Pas de proposition',
                atd: poste.mouvement.atd ? 'ATD' : 'ATP'
            };
        });
    }, [postes, enseignants]);

    const columns = [
        {
            title: 'Discipline',
            dataIndex: 'discipline',
            key: 'discipline',
            render: text => <Tag color={disciplineColor(text)}>{text}</Tag>,
        },
        {
            title: 'ACA',
            dataIndex: 'ACA',
            key: 'ACA',
        },
        {
            title: 'Établissement',
            dataIndex: 'etablissement',
            key: 'etablissement',
        },
        {
            title: 'Classe',
            dataIndex: 'classe',
            key: 'classe',
        },
        {
            title: 'Statut',
            dataIndex: 'status',
            key: 'status',
            filters: [
                { text: 'Vacant', value: 'Vacant' },
                { text: 'Pourvu ATD possible', value: 'Pourvu ATD possible' },
                { text: 'Pourvu ATD impossible', value: 'Pourvu ATD impossible' },
            ],
            onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
        {
            title: 'Occupant',
            dataIndex: 'occupant',
            key: 'occupant',
            render: (text, record) => (
                <span>
                    {text} { record.proposition !== record.occupant ? <Tag color="red">Départ</Tag> : '' }
                </span>
            ),
        },
        {
            title: 'Proposition',
            dataIndex: 'proposition',
            key: 'proposition',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Button icon={<EditOutlined />} onClick={() => setPourvoir(record.key)}>Pourvoir</Button>
            ),
        },
    ];

    return (
        <>
            <MouvementPourvoirModal
                posteUUID={pourvoir}
                postes={postes}
                enseignants={enseignants}
                onCancel={() => setPourvoir(null)}
            />
            <Table dataSource={dataSource} columns={columns} />
        </>
    );
}

export default Mouvement;
