import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useItemsByType, useUpdateItem } from '../api';
import { Popover, Input, Tag, List, Button, Switch, Select, Table, Row, Col, Card, Spin, Alert } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

function MouvementSynthese() {
    const [ showATDseul, setShowATDseul ] = useState(true);
    const {
        data: enseignants,
        isLoading: isLoadingEnseignants,
        error: errorEnseignants,
    } = useItemsByType('enseignant');

    const {
        data: postes,
        isLoading: isLoadingPostes,
        error: errorPostes,
    } = useItemsByType('poste');

/*
    return (
        <>
        <Switch checked={showATDseul} onChange={setShowATDseul} /> Afficher seulement les ATD
        <List>
        { Object.entries(postes).map( ([posteUUID, poste]) => {
            const enseignant = (poste.mouvement.proposition && enseignants[poste.mouvement.proposition]) || null;
            return (
poste.mouvement.proposition && 
(poste.mouvement.atd 
|| (!showATDseul && (poste.occupant !== poste.mouvement.proposition || poste.mouvement.a_pourvoir)))
            && <List.Item>
            { poste.occupant ? enseignants[poste.occupant].nom : 'vacant' }
                <ArrowRightOutlined />
                <span style={{fontWeight: 'bold'}}>{ enseignant.nom } { enseignant.prenom }</span> affecté à { poste.classe } - { poste.etablissement } - { poste.COM } en { poste.mouvement.atd ? <Tag color="blue">ATD</Tag> 
                            : <Tag color="red">ATP</Tag> }
            </List.Item>
        );
        })}
        </List>
        </>
    );
*/

// Function to transform your posts into table data
const getTableData = () => {
    return Object.entries(postes).reduce((acc, [posteUUID, poste]) => {
        const enseignant = (poste.mouvement.proposition && enseignants[poste.mouvement.proposition]) || null;
        if ( poste.mouvement.proposition &&
             (poste.mouvement.atd || poste.occupant !== poste.mouvement.proposition || poste.mouvement.a_pourvoir))
        {
            acc.push({
                key: posteUUID,
                currentOccupant: poste.occupant ? enseignants[poste.occupant].nom : 'vacant',
                newOccupant: `${enseignant.nom} ${enseignant.prenom}`,
                classeEtablissement: `${poste.classe} - ${poste.etablissement} - ${poste.COM}`,
                type: poste.mouvement.atd ? 'ATD' : 'ATP',
                disciplineProp: `${enseignant.discipline}/${poste.discipline}`,
                statut: `${enseignant.concours}-${enseignant.carriere.echelon}`,
            });
        }
        return acc;
    }, []);
};

// Columns configuration for the table
const columns = [
    {
        title: 'Act.',
        dataIndex: 'currentOccupant',
        key: 'currentOccupant',
    },
    {
        title: 'Prop.',
        dataIndex: 'newOccupant',
        key: 'newOccupant',
        render: text => <span style={{ fontWeight: 'bold' }}>{text}</span>,
    },
    { title: 'Statut', dataIndex: 'statut', key: 'statut',
    },
    {
        title: 'Discipline Ens/Poste',
        dataIndex: 'disciplineProp',
        key: 'disciplineProp',
    },
{ title: 'Echelon', dataIndex: ['carriere','echelon'], key: 'echelon',
            render: (echelon, record) =>
                echelon == 1 ? (<Tag color='blue' key={record.enseignant}>Stagiaire</Tag>) : echelon
        },


    {
        title: 'Poste',
        dataIndex: 'classeEtablissement',
        key: 'classeEtablissement',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: type => <Tag color={type === 'ATD' ? 'blue' : 'red'}>{type}</Tag>,
        filters: [
            { text: 'ATD', value: 'ATD' },
            { text: 'ATP', value: 'ATP' },
        ],
        onFilter: (value, record) => record.type.indexOf(value) === 0,
    },
];

return (
    <>
        <Table pagination={false} dataSource={getTableData()} columns={columns} />
    </>
);
}

export default MouvementSynthese;
