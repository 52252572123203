import React, { useEffect, useState } from 'react';
import { useItem, useDeleteItem, useCreateItem, useUpdateItem, useItemsByType } from '../api';
import { Tabs, Space, Input, InputNumber, Button, Modal, Form, Switch, Select, Table, Row, Col, Card, Spin, Alert } from 'antd';
import { EditFilled } from '@ant-design/icons';
import OpenPDFButton from './OpenPDFButton';

const { TextArea } = Input;
const { TabPane } = Tabs;

const predefinedTags = ['normalien', 'ulm', 'rennes', 'lyon', 'paris_saclay', 
    'prepa agreg ens', 'mpri',
    'doctorat info', 'doctorat maths',
    'etude info', 'etude maths',
    'ingenieur info',
    'profil théorique', 'profil appliqué',
    'agreg maths D' ];

function EnseignantModal({ enseignantUUID, onChange, isModalOpen, setIsModalOpen }) {
    const createItem = useCreateItem('enseignant');
    const deleteItem = useDeleteItem('enseignant');
    const [form] = Form.useForm();

    const { data: enseignant, isLoading, error } = useItem(enseignantUUID);

    useEffect(() => {
        form.resetFields();
        if (enseignant) {
            form.setFieldsValue({
                'civilite': enseignant.content.civilite,
                'nom': enseignant.content.nom,
                'prenom': enseignant.content.prenom,
                'naissance': enseignant.content.naissance,
                'discipline': enseignant.content.discipline,
                'concours': enseignant.content.concours,
                'rang': enseignant.content.rang,
                'annee': enseignant.content.annee,
                'discipline_recrutement': enseignant.content.discipline_recrutement,
                'motivation': enseignant.details.motivation,
                'commentaire': enseignant.content.commentaire,
                'email': enseignant.content.email,
                'cv': enseignant.details.cv,
                'voeux': enseignant.content.voeux.sort((a, b) => a.rang - b.rang),
                'grade': enseignant.content.carriere ? enseignant.content.carriere.grade : '',
                'echelon': enseignant.content.carriere ? enseignant.content.carriere.echelon : '',
                'date': enseignant.content.carriere ? enseignant.content.carriere.date : '',
                'tags': enseignant.content.tags,
            });
        }
    }, [enseignant]);

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    const handleDelete = () => {
        if (window.confirm('Voulez-vous vraiment supprimer cet enseignant ?')) {
            deleteItem.mutate({ uuid: enseignantUUID });
            setIsModalOpen(false);
            form.resetFields();
        }
    };

    const handleFinish = (values) => {
        if (enseignant) {
            if(onChange) {
                const { echelon, grade, date, 
                    ...rest } = values;
                onChange(enseignantUUID, {
                    ...rest,
                    carriere: {
                        echelon: echelon,
                        grade: grade,
                        date: date
                    }
                });
            }
        } else {
            const defaultEnseignant = {
                civilite: '',
                nom: '',
                prenom: '',
                naissance: '',
                concours: '',
                rang: '',
                annee: '',
                discipline_recrutement: '',
                discipline: '',
                commentaire: '',
                email: '',
                tags: [],
                carriere: {
                    grade: '',
                    echelon: '',
                    date: '',
                },
                occupe: null,
                motivation: '',
                voeux: [],
            }

            createItem.mutate(
                { content: 
                    { ...defaultEnseignant, ...values  }
                },
                { onSuccess: () => console.log('Successfully created enseignant') }
            );
        }
        setIsModalOpen(false);
    };

    return (
        <>
            <Modal title={enseignant ? 'Modifie enseignant' : 'Ajoute enseignant'} 
                width={800}
                open={isModalOpen} onCancel={handleCancel} footer={null}>
                <Form form={form} onFinish={handleFinish}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Informations personnelles" key="1">
                    <Form.Item name="civilite" label="Civilité">
                        <Select placeholder="Civilité" style={{ width: 120 }}>
                        <Select.Option value="M.">M.</Select.Option>
                        <Select.Option value="Mme">Mme</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="nom" label="Nom"><Input /></Form.Item>
                    <Form.Item name="prenom" label="Prénom"><Input /></Form.Item>
                    <Form.Item name="naissance" label="Année de naissance"><Input /></Form.Item>
                    <Form.Item name="email" label="Email"><Input /></Form.Item>
                    <Form.Item name="discipline" label="Discipline">
                        <Select placeholder="Discipline" style={{ width: 120 }}>
                        <Select.Option value="Maths">Maths</Select.Option>
                        <Select.Option value="Info">Info</Select.Option>
                        <Select.Option value="SII">SII</Select.Option>
                        <Select.Option value="EG">EG</Select.Option>
                        <Select.Option value="PC">PC</Select.Option>
                        <Select.Option value="Info en cours">Info en cours</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="concours" label="Concours"><Input /></Form.Item>
                    <Form.Item name="rang" label="Rang"><Input /></Form.Item>
                    <Form.Item name="annee" label="Année"><Input /></Form.Item>
                    <Form.Item name="discipline_recrutement" label="Discipline de recrutement"><Input /></Form.Item>
                    <Form.Item name="grade" label="Grade"><Input /></Form.Item>
                    <Form.Item name="echelon" label="Échelon"><Input /></Form.Item>
                    <Form.Item name="date" label="Date de l'échelon"><Input /></Form.Item>
<Form.Item name="tags" label="Tags">
  <Select
    mode="tags"
    style={{ width: '100%' }}
    placeholder="Selectionner ou entrer des tags"
    options={predefinedTags.map(tag => ({ value: tag }))}
  />
</Form.Item>

        </TabPane>
        <TabPane tab="Vœux" key="2">
        <Form.List name="voeux">
        {(fields, { add, remove }) => {
            return (<div style={{ maxHeight: 500, overflowY: 'auto' }}>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} 
                    align="baseline">
                <Form.Item name={[name, 'rang']} fieldKey={[fieldKey, 'rang']}
                    rules={[{ required: true, message: 'Rang manquant' }]}
                    >
                    <InputNumber
                    min={1} max={20}
                    parser={value => value ? parseInt(value, 10) : undefined}
                style={{width:50}} placeholder="Rang" />
                </Form.Item>
                <Form.Item
                {...restField}
                name={[name, 'type']}
                fieldKey={[fieldKey, 'type']}
                rules={[{ required: true, message: 'Type manquant' }]}
                >
                <Select placeholder="Type de vœu" style={{ width: 120 }}>
                <Select.Option value="ETB">Etab.</Select.Option>
                <Select.Option value="COM">Com.</Select.Option>
                <Select.Option value="GEO">Geo</Select.Option>
                <Select.Option value="DPT">Dep.</Select.Option>
                <Select.Option value="ACA">Acad</Select.Option>
                </Select>
                </Form.Item>
                <Form.Item
                {...restField}
                name={[name, 'valeur']}
                fieldKey={[fieldKey, 'valeur']}
                rules={[{ required: true, message: 'Valeur manquante' }]}
                >
                <Input style={{width:400}} placeholder="Valeur" />
                </Form.Item>
                <Button type="danger" onClick={() => remove(name)}>Suppr.</Button>
                </Space>
            ))}
            <Button type="dashed" onClick={() => add()} block>
            Ajoute vœu
            </Button>
            </div>
        );}}
        </Form.List>
        </TabPane>
        <TabPane tab="Dossier" key="3">
        { enseignant && enseignant.details && enseignant.details.dossier_pdf && <OpenPDFButton base64PDF={ enseignant.details.dossier_pdf } /> }
        <Form.Item 
        labelCol={{ span: 24 }}
        name="motivation" label="Motivation"><TextArea rows="8" placeholder="lettre de motivation" /></Form.Item>
                <Form.Item 
        labelCol={{ span: 24 }}
        name="commentaire" label="Commentaire"><TextArea rows="8" placeholder="commentaire sur la candidature" /></Form.Item>
        </TabPane>
        </Tabs>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {enseignant ? 'Modifie enseignant' : 'Ajoute enseignant'}
                        </Button>
                        <Button onClick={handleDelete}>Supprime enseignant</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

function EnseignantDisplayModal({ enseignantUUID, onChange }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    return (
        <>
        <Button type="primary" onClick={() => showModal()}
            icon={<EditFilled/> } size='small' />
        { isModalOpen &&
            <EnseignantModal 
                enseignantUUID={ enseignantUUID } onChange={ onChange }
                isModalOpen={ isModalOpen } setIsModalOpen={ setIsModalOpen }
            /> }
        </>
    );
}

export default EnseignantDisplayModal;
