import CryptoJS from 'crypto-js';
import pako from 'pako';

const crypted_challenge =  '7903247875fd6d30e6e044060d96bfb1';

const wordArrayToUint8Array = (wordArray) => {
  const words = wordArray.words;
  const length = wordArray.sigBytes;
  const result = new Uint8Array(length);
  let bytes, i = 0, j = 0;

  while (length > i) {
    bytes = words[j++];
    result[i++] = (bytes >> 24) & 0xff;
    result[i++] = (bytes >> 16) & 0xff;
    result[i++] = (bytes >> 8) & 0xff;
    result[i++] = bytes & 0xff;
  }

  return result;
};

export const validateKey = (hex_key) => {
    const key = CryptoJS.enc.Hex.parse(hex_key);
    const crypted = CryptoJS.AES.encrypt('success', key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7 }).ciphertext.toString(CryptoJS.enc.Hex);
    return crypted == crypted_challenge;
};

export const decrypt = (data) => {
    const key = CryptoJS.enc.Hex.parse(localStorage.getItem('encryptionKey'));
    const b64data = data
    const decrypted = CryptoJS.AES.decrypt(b64data, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7 });
    return pako.ungzip(wordArrayToUint8Array(decrypted), {to: 'string'});
}

export const encrypt = (data) => {
    const key = CryptoJS.enc.Hex.parse(localStorage.getItem('encryptionKey'));
    const gzippedData = pako.gzip(data, {to: 'string'});
    const wordArray = CryptoJS.lib.WordArray.create(gzippedData);
    const encrypted = CryptoJS.AES.encrypt(wordArray, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7 });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}

export const encryptHex = (data) => {
    const key = CryptoJS.enc.Hex.parse(localStorage.getItem('encryptionKey'));
    const gzippedData = pako.gzip(data, {to: 'string'});
    const wordArray = CryptoJS.lib.WordArray.create(gzippedData);
    const encrypted = CryptoJS.AES.encrypt(wordArray, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7 });
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
}
